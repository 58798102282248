<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center v-if="cartData.length > 0" pb-12>
      <v-flex xs12 lg10 xl10 pt-4>
        <span class="popsemibold" v-if="cartData.length > 0">
          MY CART ({{ cartData.length }})
        </span>
        <v-layout wrap justify-center py-4>
          <v-flex xs12 md7>
            <v-layout wrap v-for="(item, i) in cartData" :key="i">
              <v-flex xs12 md12>
                <CartDetails
                  v-bind:storage="item"
                  v-bind:isCart="true"
                  :flag="flag"
                  @stepper="winStepper"
                />
              </v-flex>
            </v-layout>
            <!-- <v-layout v-if="expiredData.length > 0" wrap pt-5>
              <v-flex xs12>
                <span class="bookingHeading">BOOKING EXPIRED</span>
              </v-flex>
              <v-flex xs12 md12>
                <CartDetails
                  v-bind:storage="expiredData"
                  v-bind:isCart="false"
                  @stepper="winStepper"
                />
              </v-flex>
            </v-layout> -->
          </v-flex>
          <v-flex xs12 md5 v-if="cartData.length > 0">
            <PriceDetails
              v-bind:storage="summary"
              v-bind:totalAmount="summary.finalAmount"
              v-bind:isCart="true"
              @stepper="winStepper2"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center fill-height v-if="cartData.length < 1">
      <v-flex xs12 lg10 xl10 pt-4 align-self-center text-left>
        <span class="popsemibold" >
          MY CART
        </span>
      </v-flex>
      <v-flex xs12 pa-16 text-center align-self-center>
        <span class="popregular">Your cart is empty!</span>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import CartDetails from "./cartDetails";
import PriceDetails from "./prodPriceDetails";
export default {
  components: {
    CartDetails,
    PriceDetails,
  },
  data() {
    return {
      appLoading: false,
      cartData: [],
      totalAmount: null,
      expiredData: [],
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      newCartData: [],
      summary: [],
      flag: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    winStepper(windowData) {
      this.showSnackBar = true;
      this.msg = windowData.pageResponse.msg;
      if (windowData.getData) {
        // this.getData()
        this.flag = true;
      }
      this.getData();
    },
    winStepper2(windowData) {
      if (windowData.getData) this.getData();
    },
    getData() {
      if(localStorage.getItem('GuestID')) {
        this.cartData=[]
      this.summary=[]
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/productcart/getlist",
        // headers: {
        //   token: localStorage.getItem("token"),
        // },
        params: { 
          // update: true
          guestId : localStorage.getItem('GuestID')
           },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.cartData = response.data.data;
            this.summary = response.data.summary;
            // this.newCartData = response.data.result;
            // this.expiredData = response.data.expired;
            // this.totalAmount = response.data.finalAmount;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
        this.guestInfo()

      } else {
        this.cartData=[]
      this.summary=[]
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/productcart/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        // params: { 
        //   guestId : localStorage.getItem('GuestID')
        //    },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.cartData = response.data.data;
            this.summary = response.data.summary;
            // this.newCartData = response.data.result;
            // this.expiredData = response.data.expired;
            // this.totalAmount = response.data.finalAmount;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
      }
    },
    guestInfo() {
      this.$store.commit("appLoading", true);
      axios({
        method: "POST",
        url: "cartcount/guest",
        data: {
          guestId: localStorage.getItem("GuestID"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("appLoading", false);
            this.$store.commit("changeCart", response.data.cartcount);
          } else {
            this.$store.commit("appLoading", false);
          }
        })
        .catch((err) => {
          this.$store.commit("appLoading", false);
          console.log(err);
        });
    },

    // formatDate(item) {
    //   var dt = new Date(item);
    //   var day = dt.getDate();
    //   var year = dt.getFullYear();
    //   dt = dt.toString();
    //   var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
    //   return strTime;
    // },
    formatDate(item) {
      // var dt = new Date('2014-8-20');
      var dt = new Date(item);
      // var a=dt.toISOString()
      // console.log("aa",a)
      // console.log("init",dt)
      // var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toUTCString();
      var strTime = dt.slice(4, 11) +  " " + year + " ";
      // var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
      // console.log("dd",dt)
      // console.log("bef4",item)
      return strTime;
    },
  },
};
</script>
