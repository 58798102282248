<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap v-if="newCart">
      <v-flex xs12 md11 lg10 px-2 px-md-0>
        <!-- <template v-for="(cart, index) in storage"> -->
        <v-layout wrap justify-center pt-0>
          <v-flex md12>
            <v-card tile outlined>
              <v-layout wrap pa-2>
                <v-flex xs3 md3 align-self-start style="cursor: pointer">
                  <router-link :to="'/product/' + newCart.product._id">
                    <v-img
                      v-if="newCart.product.images.length > 0"
                      :src="mediaURL + newCart.product.images[0]"
                      width="100%"
                      contain
                    >
                      <template v-slot:placeholder>
                        <ImageLoader />
                      </template>
                    </v-img>
                  </router-link>
                </v-flex>

                <v-flex
                  xs7
                  sm8
                  md8
                  px-2
                  align-self-start
                  style="cursor: pointer"
                >
                  <span
                    @click="routeRedirect(newCart)"
                    class="blink"
                    style="font-size: 18px; font-family: poppinsbold"
                  >
                    {{ newCart.product.name }}
                  </span>
                  <v-layout wrap>
                    <v-flex
                      xs12
                      v-if="newCart.unitname"
                      style="
                        font-family: poppinsregular;
                        color: #53a874;
                        font-size: 14px;
                      "
                    >
                      <span v-if="newCart.unitname">{{ newCart.unitname }}</span
                      ><span v-if="newCart.sizename">
                        - {{ newCart.sizename }}</span
                      >
                    </v-flex>
                    <v-flex xs12 align-self-center>
                      <span
                        style="
                          font-family: poppinsregular;
                          color: #3c3c3c;
                          font-size: 14px;
                        "
                        >WTI</span
                      >
                    </v-flex>
                    <v-flex
                      xs12
                      align-self-center
                      v-if="newCart.productTotalPrice"
                    >
                      <span style="font-family: poppinsbold; font-size: 20px"
                        >Rs. {{ newCart.productTotalPrice }}</span
                      >
                    </v-flex>
                    <!-- <v-flex xs12 text-left>
                      <span
                        v-if="storage.limit > 0"
                        class=""
                        style="
                          font-family: poppinsregular;
                          font-size: 14px;
                          color: #53a874;
                        "
                      >
                        In Stock ({{ storage.limit }})
                      </span>
                      <span
                        v-else
                        class=""
                        style="
                          font-family: poppinsregular;
                          font-size: 14px;
                          color: red;
                        "
                      >
                        Out of Stock
                      </span>
                    </v-flex> -->
                    <v-flex xs12 py-2 v-if="newCart.quantity">
                      <v-layout wrap justify-start>
                        <v-flex py-xl-6 xs6 xl3 lg4 text-left pt-3>
                          <span
                            style="
                              font-family: poppinssemibold;
                              font-size: 16px;
                              color: #000000;
                            "
                            >Quantity</span
                          >
                          <!-- <span v-if="$vuetify.breakpoint.name!='xs'">({{ newCart.limit }})</span> -->
                        </v-flex>
                        <v-flex
                          py-2
                          py-lg-0
                          xs12
                          sm5
                          md5
                          lg5
                          xl5
                          px-sm-1
                          px-lg-0
                          px-xl-10
                          align-self-center
                          text-center
                        >
                          <v-card flat tile outlined>
                            <v-layout wrap justify-center pa-1>
                              <v-flex xs2 sm2 md2 align-self-center text-left>
                                <v-icon
                                  @click="
                                    newCart.quantity--,
                                      updateCart(
                                        newCart._id,
                                        newCart.quantity,
                                        newCart.product._id,
                                        newCart.unitid,
                                        newCart.sizeid
                                      )
                                  "
                                  :disabled="newCart.quantity <= 1"
                                >
                                  mdi-minus
                                </v-icon>
                              </v-flex>
                              <v-flex
                                xs6
                                sm6
                                md8
                                align-self-center
                                pa-1
                                text-center
                              >
                                <!-- <v-btn outlined tile> -->
                                  <span class="poppinssemibold">
                                    {{ newCart.quantity }}
                                  </span>
                                <!-- </v-btn> -->
                              </v-flex>
                              <v-flex xs2 sm2 md2 align-self-center text-right>
                                <v-icon
                                  :disabled="newCart.quantity >= newCart.limit"
                                  @click="
                                    newCart.quantity++,
                                      updateCart(
                                        newCart._id,
                                        newCart.quantity,
                                        newCart.product._id,
                                        newCart.unitid,
                                        newCart.sizeid
                                      )
                                  "
                                >
                                  mdi-plus
                                </v-icon>
                              </v-flex>
                            </v-layout>
                          </v-card>
                          <!-- <v-text-field
                            id="slotInput"
                            class="boxed"
                            placeholder="1"
                            outlined
                            hide-details
                            dense
                            color="#CCCCCC"
                            background-color="#FFFFFF"
                            append-outer-icon="mdi-plus"
                            readonly
                            v-model="newCart.quantity"
                          >
                            <template v-slot:prepend>
                              <v-icon
                                @click="
                                  newCart.quantity--,
                                    updateCart(
                                      newCart._id,
                                      newCart.quantity,
                                      newCart.product._id,
                                      newCart.unitid,
                                      newCart.sizeid
                                    )
                                "
                                :disabled="newCart.quantity <= 1"
                              >
                                mdi-minus
                              </v-icon>
                            </template>
                            <template v-slot:append-outer>
                              <v-icon
                                :disabled="newCart.quantity >= newCart.limit"
                                @click="
                                  newCart.quantity++,
                                    updateCart(
                                      newCart._id,
                                      newCart.quantity,
                                      newCart.product._id,
                                      newCart.unitid,
                                      newCart.sizeid
                                    )
                                "
                              >
                                mdi-plus
                              </v-icon>
                            </template>
                          </v-text-field> -->
                        </v-flex>

                        <!-- <v-flex
                          v-else
                          py-2
                          py-lg-0
                          xs6
                          sm3
                          md5
                          lg4
                          xl4
                          px-sm-1
                          px-lg-0
                          px-xl-10
                          align-self-center
                          text-center
                        >
                          <v-text-field
                            id="slotInput"
                            class="boxed"
                            placeholder="1"
                            outlined
                            hide-details
                            dense
                            color="#CCCCCC"
                            background-color="#FFFFFF"
                            append-outer-icon="mdi-plus"
                            readonly
                            v-model="storage.quantity"
                          >
                            <template v-slot:prepend>
                              <v-icon
                                @click="storage.quantity--"
                                :disabled="storage.quantity <= 1"
                              >
                                mdi-minus
                              </v-icon>
                            </template>
                            <template v-slot:append-outer>
                              <v-icon
                                @click="storage.quantity++"
                                :disabled="
                                  storage.quantity ==
                                  storage.product.totalQuantity
                                "
                              >
                                mdi-plus
                              </v-icon>
                            </template>
                          </v-text-field>
                        </v-flex> -->
                      </v-layout>
                      <!-- <v-layout wrap v-else>
                        <v-flex py-xl-6 xs12 xl12 text-left pt-3>
                          <span
                            class=""
                            style="
                              font-family: poppinsregular;
                              font-size: 14px;
                              color: red;
                            "
                          >
                            Out of Stock
                          </span>
                          <br />
                          <span
                            style="font-family: poppinsregular; font-size: 14px"
                          >
                            Please remove the product and continue to checkout
                          </span>
                        </v-flex>
                      </v-layout> -->
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs2 sm1 md1>
                  <v-btn
                    plain
                    class="closebtn"
                    @click="
                      selectedCart = newCart;
                      deleteDialogue = true;
                    "
                  >
                    <v-icon style="font-size: 16px"
                      >mdi-close-circle-outline</v-icon
                    >
                  </v-btn>
                </v-flex>
              </v-layout>

              <!-- <v-layout wrap pa-2 v-if="cart">
                  <v-flex xs12>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 v-for="(item, i) in cart" :key="i">
                    <v-layout wrap>
                      <v-flex md4 text-capitalize>
                        <span class="pageForm" style="font-size: 14px">
                          {{ item.Name }}
                        </span>
                        <span class="textField2" style="font-size: 14px">
                          x {{ item.Count }}
                        </span>
                      </v-flex>
                      <v-flex md7 text-right align-self-end>
                        <span class="textField2" style="font-size: 16px">
                          {{ item.Total }} INR
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout> -->
              <!-- <v-layout v-if="!isCart" wrap>
                  <v-flex
                    md12
                    pa-2
                    text-center
                    style="background-color: #720000"
                  >
                    <span class="buttonText"
                      >This product is currently unavailable
                    </span>
                  </v-flex>
                </v-layout> -->
              <!-- <v-divider></v-divider> -->
              <!-- <v-layout v-if="(cart.isValid && isCart) || !isCart" wrap py-2>
                  <v-flex md4 pl-2>
                    <span class="textField2" style="font-size: 14px">
                      Programme Total
                    </span>
                  </v-flex>
                  <v-flex md7 text-right align-self-end>
                    <span
                      style="font-family: sofiaProSemiBold; font-size: 16px"
                    >
                      {{ cart.programTotal }} INR</span
                    >
                  </v-flex>
                </v-layout>
                <v-layout v-else wrap>
                  <v-flex
                    md12
                    pa-2
                    text-center
                    v-if="cart.issue"
                    style="background-color: #720000"
                  >
                    <span class="buttonText">{{ cart.issue.msg }} </span><br />
                    <span class="buttonText">
                      Please Remove the program and continue to checkout
                    </span>
                  </v-flex>
                </v-layout> -->
            </v-card>
          </v-flex>
        </v-layout>
        <!-- </template> -->
        <v-dialog v-model="deleteDialogue" width="400">
          <v-card>
            <v-layout pa-4 wrap justify-center>
              <v-flex xs12>
                <span
                  style="
                    color: #000000;
                    font-family: poppinssemibold;
                    font-size: 16px;
                  "
                  >Do you want to remove this product from cart?</span
                >
              </v-flex>
              <v-flex xs6 px-2 py-4>
                <v-btn
                  small
                  outlined
                  tile
                  block
                  depressed
                  dark
                  color="#C62525"
                  @click="removeCartData"
                  class="itemValue poppinsregular"
                >
                  Yes
                </v-btn>
              </v-flex>
              <v-flex xs6 px-2 py-4>
                <v-btn
                  small
                  tile
                  block
                  depressed
                  dark
                  color="#1FB436"
                  @click="deleteDialogue = false"
                  class="itemValue poppinsregular"
                >
                  No
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage", "isCart", "flag"],
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      deleteDialogue: false,
      selectedCart: {},
      limit: 0,
      newCart: null,
    };
  },
  watch: {
    flag() {
      if (this.flag) {
        this.observe();
      }
    },
    // storage: {
    //   handler(val) {
    //     if(val.quantity) {
    //       this.updateCart(val._id,val.quantity,val.product._id,val.unitid,val.sizeid)
    //     }
    //   }
    // },
    // storage() {
    //   if (this.storage.product.units.length > 0) {
    //     for (let i = 0; i < this.storage.product.units.length; i++) {
    //       if (this.storage.product.units[i].size.length > 0) {
    //         for (
    //           let j = 0;
    //           j < this.storage.product.units[i].size.length;
    //           j++
    //         ) {
    //           if (
    //             this.storage.sizeid == this.storage.product.units[i].size[j]._id
    //           )
    //             this.storage.limit =
    //               this.storage.product.units[i].size[j].stock;
    //           break;
    //         }
    //       } else {
    //         if (this.storage.unitid == this.storage.product.units[i]._id)
    //           this.storage.limit = this.storage.product.units[i].stock;
    //         break;
    //       }
    //     }
    //   } else {
    //     this.storage.limit = this.storage.product.totalQuantity;
    //   }
    //   console.log(this.storage.limit);
    // },
    quantity() {
      if (this.$route.query.sizeid) {
        this.newprice = this.quantity * this.sizeType.price;
      } else if (this.$route.query.unitid && !this.$route.query.sizeid)
        this.newprice = this.quantity * this.sizeType.price;
      else if (!this.$route.query.unitid && !this.$route.query.sizeid)
        this.newprice = this.quantity * this.sizeType.unitPrice;
      // this.getPrice();
    },
  },
  beforeMount() {
    this.getUser();
    this.observe();
    // if(this.newCart.limit<=0) {
    //   this.updateCart(this.storage._id, 0, this.storage.product._id, this.storage.unitid, this.storage.sizeid)

    // }
  },

  methods: {
    observe() {
      if (this.storage) {
        if (this.storage.product.units.length > 0) {
          for (let i = 0; i < this.storage.product.units.length; i++) {
            if (this.storage.product.units[i].size.length > 0) {
              for (
                let j = 0;
                j < this.storage.product.units[i].size.length;
                j++
              ) {
                if (
                  this.storage.sizeid ==
                  this.storage.product.units[i].size[j]._id
                ) {
                  // console.log("test jjjj55jj in",this.storage.limit)
                  this.storage.limit =
                    this.storage.product.units[i].size[j].stock;
                  // console.log("test in",this.storage.limit)
                }
              }
            } else {
              if (this.storage.unitid == this.storage.product.units[i]._id) {
                this.storage.limit = this.storage.product.units[i].stock;

                // console.log("test 1111 in",this.storage.limit)
              }
            }
          }
        } else {
          this.storage.limit = this.storage.product.totalQuantity;
        }
        this.newCart = this.storage;
        // console.log(this.newCart.productTotalPrice);
      }
    },
    routeRedirect(cart) {
      // if (!this.isCart && !cart.product.totalQuantity <= 0) {
      //   this.$router.push("/Shopping/Products");
      // }
      this.$router.push(
        "/product/" +
          cart.product._id +
          "?unit=" +
          cart.unitname +
          "&size=" +
          cart.sizename
      );
    },
    removeCartData() {
      this.appLoading = true;
      if (localStorage.getItem("GuestID")) {
        axios({
          method: "POST",
          url: "/productcart/remove",
          // headers: {
          //   token: localStorage.getItem("token"),
          // },
          data: {
            id: this.selectedCart._id,
            guestId: localStorage.getItem("GuestID"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.getUser();
              this.deleteDialogue = false;
              // this.$store.commit("changeCart", response.data.cartcount);

              this.$emit("stepper", {
                type: "Delete",
                pageResponse: response.data,
              });
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      } else {
        axios({
          method: "POST",
          url: "/productcart/remove",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            id: this.selectedCart._id,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.getUser();
              this.deleteDialogue = false;
              // this.$store.commit("changeCart", response.data.cartcount);

              this.$emit("stepper", {
                type: "Delete",
                pageResponse: response.data,
              });
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    updateCart(id, qty, prodId, unit, size) {
      // this.appLoading = true;
      if (localStorage.getItem("GuestID")) {
        axios({
          method: "POST",
          url: "/productcart/update",
          // headers: {
          //   token: localStorage.getItem("token"),
          // },
          data: {
            id: id,
            product: prodId,
            unitid: unit,
            sizeid: size,
            quantity: qty,
            guestId: localStorage.getItem("GuestID"),
            // deliveraddressid: this.deliveryAddressId,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.$emit("stepper", {
                getData: true,
                pageResponse: response.data,
              });
            } else {
              this.$emit("stepper", {
                getData: true,
                pageResponse: response.data,
              });
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      } else {
        axios({
          method: "POST",
          url: "/productcart/update",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            id: id,
            product: prodId,
            unitid: unit,
            sizeid: size,
            quantity: qty,
            // deliveraddressid: this.deliveryAddressId,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.$emit("stepper", {
                getData: true,
                pageResponse: response.data,
              });
            } else {
              this.$emit("stepper", {
                getData: true,
                pageResponse: response.data,
              });
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    getUser() {
      axios({
        method: "POST",
        url: "/user/me/product",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("userData", response.data.data);
            this.$store.commit("userType", response.data.data.role);
            this.$store.commit("changeCart", response.data.cartcount);
            // this.$store.commit(
            //   "CartType",
            //   response.data.userdata.user.cartType
            // );
            // console.log("Store=",this.$store.state.userData)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // formatDate(item) {
    //   var dt = new Date(item);
    //   var day = dt.getDate();
    //   var year = dt.getFullYear();
    //   dt = dt.toString();
    //   var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
    //   return strTime;
    // },
    formatDate(item) {
      // var dt = new Date('2014-8-20');
      var dt = new Date(item);
      // var a=dt.toISOString()
      // console.log("aa",a)
      // console.log("init",dt)
      // var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toUTCString();
      var strTime = dt.slice(4, 11) + " " + year + " ";
      // var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
      // console.log("dd",dt)
      // console.log("bef4",item)
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>
<style>
.content {
  font-family: sofiaProSemiBold;
  font-size: 14px;
  color: #474747;
}
.shopping {
  font-family: sofiaProSemiBold;
  color: #474747;
  font-size: 14px;
}
.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}

#slotInput {
  text-align: center;
}
.boxed {
  text-align: center;
  /* padding-inline-start: 0px !important; */
  border-radius: 0px;
}
.blink:hover {
  color: rgb(0, 117, 250);
}
.closebtn {
  color: #acacac;
}
.closebtn :hover {
  color: red !important;
}
</style>
