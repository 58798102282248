<template>
  <div>
    <v-layout wrap justify-center pr-md-2 pr-lg-0>
      <v-flex
        xs12
        xl9
        v-if="storage"
        style="background-color: #f7f7f7; min-height: 20vh"
      >
       <v-layout wrap justify-center px-4>
          <v-flex xs12 align-self-center py-3>
            <span style="font-family: poppinsbold; font-size: 20px"
              >₹ Pricing Details </span
            ><br />
            <v-divider></v-divider>
          </v-flex>
          <v-flex text-left xs8 py-2>
            <span class="poppinssemibold"
              >Price ({{ storage.cart.length }} Item<span
                v-if="storage.cart.length > 1"
                >s</span
              >)</span
            >
          </v-flex>
          <v-flex text-end xs4 py-2>
            <span class="poppinssemibold">₹ {{ storage.price }}</span>
          </v-flex>
          <v-flex text-left xs8 py-2>
            <span class="poppinssemibold">Payment Gateway charge</span>
          </v-flex>
          <v-flex text-end xs4 py-2>
            <span class="poppinssemibold">₹ {{ storage.paymentgatewayCharge }}</span>
          </v-flex>
          <!-- <v-flex text-left xs8 py-2>
            <span class="poppinssemibold">Shipping charge</span>
          </v-flex>
          <v-flex text-end xs4 py-2>
            <span class="poppinssemibold">₹ {{ storage.deliveryCharge }}</span>
          </v-flex> -->
        </v-layout>
        <v-layout
          wrap
          justify-center
          px-4
          py-3
          style="font-family: poppinsbold; font-size: 16px; color: #53a874"
        >
          <v-flex xs6 text-left> TOTAL AMOUNT </v-flex>
          <v-flex xs6 text-right align-self-end> {{ totalAmount }} INR </v-flex>
        </v-layout>

        <v-layout wrap v-if="totalAmount > 0" pa-4>
          <v-flex xs12>
            <!-- <v-btn depressed small block to="/shipDetails" dark :ripple="false" color="#53A874">
              <span class="poppinsbold"> PLACE ORDER</span>
            </v-btn> -->
            <v-btn depressed small block @click="decideTo()" dark :ripple="false" color="#53A874">
              <span class="poppinsbold"> PLACE ORDER</span>
            </v-btn>
          </v-flex>
        </v-layout>
        <!-- <v-layout wrap v-if="totalAmount > 0" pa-4>
          <v-flex xs12>
            <CheckOut
              v-bind:totalAmount="totalAmount"
              v-bind:storage="storage"
              :key="storage.finalAmount"
            />
          </v-flex>
        </v-layout> -->
      </v-flex>

      <v-flex align-self-center text-center v-else>
        <span class="popsemibold">Your Cart is empty</span>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
// import CheckOut from "./checkOut";
export default {
  props: ["storage", "totalAmount", "isCart"],
  // components: { CheckOut },
  data() {
    return {
      vehicle: [],
    };
  },
  beforeMount() {
    // this.getSettings();
  },
  methods: {
    decideTo() {
      if(localStorage.getItem('GuestID')) {
        this.$router.push('/login') 
      }
      else {
        this.$router.push('/shipDetails') 
      }
    },
    // getSettings() {
    //   this.appLoading = true;
    //   axios({
    //     method: "GET",
    //     url: "/static/settings/get",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       this.vehicles[0].charge = response.data.data.lightVehicleCharge;
    //       this.vehicles[1].charge = response.data.data.heavyVehicleCharge;
    //       // this.vehicles[2].charge = response.data.data.camera;
    //       // this.vehicles[3].charge = response.data.data.moviecamera;
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    // formatDate(item) {
    //   var dt = new Date(item);
    //   var day = dt.getDate();
    //   var year = dt.getFullYear();
    //   dt = dt.toString();
    //   var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
    //   return strTime;
    // },
    formatDate(item) {
      // var dt = new Date('2014-8-20');
      var dt = new Date(item);
      // var a=dt.toISOString()
      // console.log("aa",a)
      // console.log("init",dt)
      // var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toUTCString();
      var strTime = dt.slice(4, 11) +  " " + year + " ";
      // var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
      // console.log("dd",dt)
      // console.log("bef4",item)
      return strTime;
    },
  },
};
</script>

<style>
.vehicleSelector .v-input__slot {
  background-color: #f7f7f7 !important;
}
</style>